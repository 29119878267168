@import "journey-ui/styles/index.css";
@import "vars.css";
@import "font.css";
@import "spinner.css";
@import 'mapbox-gl/dist/mapbox-gl.css';

.j-app, .j-previous-page {
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom) !important;
}

@layer components {
  .j-nav-content {
    @apply p-1 md:p-0
  }

  .j-content, .j-app, .j-page {
    @apply bg-white
  }

  /* Maintain a max of 1080px width, thats all we support. */
  .content-container {
    max-width: 1080px;
    width: 100%;
  }

  .item-edit-form {
    @apply flex items-center;

    .j-card {
      @apply md:max-w-[700px] rounded-lg shadow-xl
    }
  }

  .menu-page {
    /* Due to sticky section header, we need nav to be relative really. */
    .j-nav {
      @apply relative #{!important};
    }
  }

  .j-nav {
    @apply h-16 md:h-20;

    //background: rgba(var(--header_bg_color));
    background: rgb(30 30 30);
    color: rgb(255,255,255);
    //color: rgb(var(--header_text_color, '255,255,255')) #{!important};
  }

  .app-header-padding {
    @apply h-20 flex-shrink-0
  }

  .j-logo {
    @apply h-16
  }

  .cart-notifications .j-popover-content {
    @apply p-1 md:px-5 overflow-y-visible overflow-x-hidden #{!important};
  }

  .j-button.nav-button {
    @apply hidden md:inline-flex rounded-none border-none h-20 rounded-none font-bold lg:text-lg px-3 lg:px-4 uppercase
  }

  .j-button.primary-nav-button {
    @apply text-white lg:px-10 primary;
  }

  .j-button:not(.j-accordion-header):not(.nav-button), .j-select-button {
    @apply rounded-full text-sm font-bold
  }

  .j-alert .j-modal-footer .j-button {
    @apply rounded-none
  }

  .menu-page .j-content {
    @apply py-0
  }

  .quantity-input input {
    @apply text-center
  }

  .j-card {
    @apply bg-white rounded p-2 shadow w-full space-y-2
  }

  .full-screen .j-content {
    @apply p-0;
  }

  .j-footer {
    @apply z-10
  }

  .j-drawer {
    @apply flex flex-col;
  }

  .cart-item-count {
    @apply w-[20px] h-[20px] flex text-white justify-center text-sm items-center bg-red-600 absolute top-[-0.2rem] right-[-0.2rem] rounded-full bg-opacity-90 align-middle
  }

  .map-container {
    @apply h-full w-full
  }

  .location-page .j-content {
    @apply p-0;
  }

  .section-header {
    @apply heading-font justify-center space-x-3 text-lg px-0 rounded-none #{!important};

    span {
      @apply uppercase font-bold
    }
  }

  .checkout-tabs .j-tab-buttons {
    background: #f3f3f3;
    border-radius: 22px;
    padding-left: 4px;
  }

  .j-modal-header .j-modal-header-close {
    @apply bg-transparent;
  }

  .topping-btn .j-checkbox-input-label {
    @apply flex-grow
    /*@apply w-full*/
  }

  .login-page .j-content {
    @apply p-0;
  }

  .color-inherit {
    color: inherit #{!important};
  }

  .is-email-marketable-wrapper .j-checkbox-input-label {
    max-width: 90%;
  }
}

#dropin-container {
  min-height: 320px;
}

.braintree-placeholder {
  margin: 0;
  height: 2px #{!important};
}

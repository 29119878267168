
/* When in a button, put spinner in center. */
.j-button .spinner-dots-wrapper {
  @apply w-full h-full flex justify-center items-center
}

.spinner-dots {
  margin: 0 auto 0;
  text-align: center;
}

.spinner-dots > div {
  @apply rounded-full border-4 border-primary bg-white;
  margin-left: 1px;
  margin-right: 1px;
  width: 17px;
  height: 17px;

  display: inline-block;
  -webkit-animation: sk-bouncedelay 0.6s infinite ease-in-out both;
  animation: sk-bouncedelay 0.6s infinite ease-in-out both;
}

.spinner-dots .bounce1 {
  -webkit-animation-delay: -0.18s;
  animation-delay: -0.18s;
}

.spinner-dots .bounce2 {
  -webkit-animation-delay: -0.09s;
  animation-delay: -0.09s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0.3) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
  } 40% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
    }
}

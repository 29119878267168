:root {
  --primary: 178,30,35;
  --primary-contrast: 245, 245, 245;
  --secondary: 135, 143, 49;
  --footer: 250, 250, 250;
  --body: 248, 248, 248;
  --body-hover: 240, 240, 240;
  --secondary-body: 255,255,255;
  --typography: 20, 20, 20;

  --panel: 243, 244, 246;

  --input-background: 243, 244, 246;
  --input-border: 215, 218, 2225;
  --input-border-focus: 187, 192, 204;

  --scrollbar-background: 230,230,230;
  --scrollbar-radius: 0;
  --scrollbar-size: 6px;
  --scrollbar-inner-padding: 0;

  --spacing-popover: .5rem;
  --spacing-field: 1rem;
  --base-roundness: 4px;
}
